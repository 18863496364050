<template>
    <div>
        <fieldset>
            <Field
                v-model="fullname"
                :error="formErrors.lastname || formErrors.firstname || formErrors.patronymic"
                title="ФИО"
                subtitle="(отчество можно не указывать если нет)"
            />
        </fieldset>
        <fieldset>
            <fieldset>
                <Date
                    v-model="form.birthday"
                    :error="formErrors.birthday"
                    title="Дата рождения"
                />
                <ZSwitch
                    v-model="form.gender"
                    :error="formErrors.gender"
                    title="Пол"
                    :list="[{
                        key: '0',
                        value: 'Мужской'
                    }, {
                        key: '1',
                        value: 'Женский'
                    }]"
                />
            </fieldset>
        </fieldset>
    </div>
</template>

<script>
import sbg from '@/mixins/sbg'
import Date from '@/components/form/field/Date'
import ZSwitch from '@/components/form/switch/Switch'

import validation from '@/mixins/validation'
import Validation from '@/ext/validation/Validation'

import { MIN as MIN_VAL, MAX as MAX_VAL, NAME_EXP } from '@/const/validationValues'
const NAME_RULES = [Validation.NO_FILTHY, [Validation.EXP, NAME_EXP], [Validation.MIN, MIN_VAL], [Validation.MAX, MAX_VAL]]
const NAME_ERROR_MSG = {
    [Validation.REQUIRED]: 'Укажите полное ФИО',
    [Validation.EXP]: 'Допустимы только русские буквы, дефис и апостроф'
}

const MAX_BORROWER_OLD = 90
const MIN_BORROWER_OLD = 18

import { year, month, day } from '@/helpers/date/currentDate'
const MAX_BORROWER_YEAR = `${day}.${month}.${year-MAX_BORROWER_OLD}`
const MIN_BORROWER_YEAR = `${day}.${month}.${year-MIN_BORROWER_OLD}`

import { mapGetters } from 'vuex'

export default {
    name: 'PersonalInformation',
    mixins: [
        validation,
        sbg
    ],
    data() {
        return {
            form: {
                firstname: '',
                lastname: '',
                patronymic: '',
                birthday: '',
                gender: ''
            },
            formRules: {
                firstname: [
                    Validation.REQUIRED,
                    ...NAME_RULES
                ],
                lastname: [
                    Validation.REQUIRED,
                    ...NAME_RULES
                ],
                patronymic: NAME_RULES,
                birthday: [Validation.REQUIRED, Validation.DATE, [Validation.DATE_LESS, MIN_BORROWER_YEAR], [Validation.DATE_MORE, MAX_BORROWER_YEAR]],
                gender: [
                    Validation.REQUIRED
                ]
            }
        }
    },
    created() {
        this.form.firstname = this.user.contactData.firstname
        this.form.lastname = this.user.contactData.lastname
        this.form.patronymic = this.user.contactData.patronymic
        this.form.birthday = this.user.contactData.birthday
        this.form.gender = String(this.user.contactData.gender) || '0'
    },
    computed: {
        ...mapGetters({
            user: 'application/user'
        }),
        customErrorsM() {
            return  {
                firstname: NAME_ERROR_MSG,
                lastname: NAME_ERROR_MSG,
                patronymic: NAME_ERROR_MSG,
                birthday: {
                    [Validation.DATE_LESS]: this.isSbg ? `Мы не выдаем займ лицам не достигшим ${MIN_BORROWER_OLD} лет` : `Минимальный возраст застрахованного лица - ${MIN_BORROWER_OLD} лет`,
                    [Validation.DATE_MORE]: this.isSbg ? `Возраст заемщика не должен превышать ${MAX_BORROWER_OLD} лет` : `Мы не страхуем лица страше ${MAX_BORROWER_OLD} лет`,
                },
            }
        },
        fullname: {
            get() {
                return [
                    this.form.lastname,
                    this.form.firstname,
                    this.form.patronymic
                ].filter(item => item).join(' ')
            },
            set(v) {
                const array = v.trim().split(' ')

                this.form.lastname = array.shift() || ''
                this.form.firstname = array.shift() || ''
                this.form.patronymic = array.join(' ')
            }
        }
    },
    watch: {
        'form.birthday'(v) {
            this.$emit('changeBirthday', v)
        }
    },
    components: {
        ZSwitch,
        Date,
    }
}
</script>
