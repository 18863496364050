<template>
    <FormTemplate
        @submit="validate"
        class="contact-view"
    >
        <PersonalInformation
            ref="personalInformation"
        />
        <h3 class="contact-view__title">
            Паспортные данные
        </h3>
        <PassportInformation
            ref="passportInformation"
        />
    </FormTemplate>
</template>

<script>
import './contact-view.scss'

import Store from '@/store'

import PersonalInformation from '@/applications/loan-application/components/personal-information/PersonalInformation'
import PassportInformation from '@/applications/loan-application/components/passport-information/PassportInformation'

import openRoute from '@/helpers/router/openRoute'

import setMask from '@/helpers/string/setMask'

export default {
    name: 'Contact',
    methods: {
        validate() {
            this.$refs.personalInformation.validate()
            const isPersonalValid = this.$refs.personalInformation.isValid

            const passportScroll = !isPersonalValid
            this.$refs.passportInformation.validate(passportScroll)

            console.log(this.$refs.passportInformation.isValid && isPersonalValid)

            this.$refs.passportInformation.isValid && isPersonalValid && this.submit();
        },
        async submit() {
            const { next_step, noValid } = await this.$store.dispatch('application/send', {
                contactData: {
                    ...this.$refs.personalInformation.form,
                    ...this.$refs.passportInformation.form.contactData
                },
                passportData: {
                    ...this.$refs.passportInformation.form.passportData,
                    passportissuecode: setMask(this.$refs.passportInformation.form.passportData.passportissuecode, '###-###')
                }
            })

            if (noValid && Object.keys(noValid)) {
                const personalInfoError = this.$refs.personalInformation.formErrors;

                personalInfoError.firstname = (noValid.firstname === false && 'Невалидное значение') || ''
                personalInfoError.lastname = (noValid.lastname === false && 'Невалидное значение') || ''
                personalInfoError.patronymic = (noValid.patronymic === false && 'Невалидное значение') || ''
                personalInfoError.birthday = (noValid.birthday === false && 'Невалидное значение') || ''
                personalInfoError.gender = (noValid.gender === false && 'Невалидное значение') || ''

                const passportInfoError = this.$refs.passportInformation.formErrors.passportData;

                passportInfoError.passportissuecode = (noValid.passportissuecode === false && 'Невалидное значение') || ''
                passportInfoError.passportnumber = (noValid.passportnumber === false && 'Невалидное значение') || ''
                passportInfoError.passportseries = (noValid.passportseries === false && 'Невалидное значение') || ''
                passportInfoError.passportissuer = (noValid.passportissuer === false && 'Невалидное значение') || ''
                passportInfoError.passportissuedate = (noValid.passportissuedate === false && 'Невалидное значение') || ''

                return;
            }

            openRoute(next_step)
        },
    },
    components: {
        PassportInformation,
        PersonalInformation,
        FormTemplate: () => import('@/templates/form/Form'),
    },
    beforeRouteEnter(to, from, next) {
        const { contactData: { phone } } = Store.getters['application/user']

        if (phone) {
            next()
        } else {
            Store.commit('application/load', false)
            next({ name: 'LoanPrimary' })
        }
    }
}
</script>